import Faq from "../components/faq/Faq";

const RouteList = [
    
    {
        label: "FAQ Page",
        isProtected: false,
        Component: Faq,
        path: "/",
    },
   
]

export default RouteList