import instance from "../http";
import { commonPath } from "../../config/paths";
import { setandgetCookies } from "../../utils/utils";
import config from "../../config/config";

export async function getPressReleaseData(_data, ip) {
    const headers = {
        "content-type": "application/json",
        token: setandgetCookies(),
        socketid: localStorage.getItem("socketId"),
        user_ip: ip,
        projectName: config.websiteLogo
    }
    const response = await instance.post(commonPath.SEND_EMBEDDING_DATA, { prompt: _data, maxTokens: 1500, temperature: 0.7 }, { headers: headers })
    return response
}

export async function sendReactionValue(questionId, selectedAnswer, ip) {
    const headers = {
        "content-type": "application/json",
        token: setandgetCookies(),
        socketid: localStorage.getItem("socketId"),
        user_ip: ip,
        projectName: config.websiteLogo
    }
    const response = await instance.put(`${commonPath.SEND_USER_REVIEW}/${questionId}/${selectedAnswer}`, {}, { headers: headers })
    return response
}