import React from "react";

import config from "../../config/config";

import logo from "../../assets/images/invoiceOwl.png";
import callHippoLogo from "../../assets/images/callHippo.png";
import allEventLogo from "../../assets/images/allEvents.png";

export default function Faqheader({
  setLoadingPromptData,
  setInputtedQuestion,
  loading,
}) {
  const headerButtonSend = () => {
    if (loading) {
      setLoadingPromptData([]);
      setInputtedQuestion("");
    }
  };

  return (
    <div className="faq-header">
      <div className="logo text-center">
        <button disabled={loading.current} onClick={() => headerButtonSend()} className="border-0 bg-transparent">
            {config.websiteLogo === "invoiceOwl" && <img src={logo} alt="invoice owl" />}
            {config.websiteLogo === "callHippo" && (
              <img src={callHippoLogo} alt="call hippo" />
            )}
            {config.websiteLogo === "allEvents" && (
              <img src={allEventLogo} alt="all events" />
            )}
        </button>
      </div>
    </div>
  );
}
