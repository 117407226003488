/**
 * Basic Configuration
 */
const config = {

    /**
   * Notification configuration
   */
    toastNotification: {
        /**
         * Toast Notification Position
         */
        position: "top-center",

        /**
         * Define time in mili second. Toast Notification will closed in time
         */
        autoCloseTimeOut: 1500,

        /**
         * it will show progress in notification
         */
        hideProgress: false,
    },

    /**
   * This is base url for api calling
   */
    baseURL: process.env.REACT_APP_API_BASEURL
        ? process.env.REACT_APP_API_BASEURL
        : "",

    /**
     * This is the website logo name
     */
    websiteLogo: process.env.REACT_APP_WEBSITE_LOGO
        ? process.env.REACT_APP_WEBSITE_LOGO : "invoiceOwl",

    /**
     * This is the landing page main text
     */
    landingPageText: process.env.REACT_APP_LANDING_PAGE_TEXT ? process.env.REACT_APP_LANDING_PAGE_TEXT : "Ask anything about Invoiceowl",

    /**
     * This is the landing page sub text
     */
    landingPageSubText: process.env.REACT_APP_LANDING_PAGE_SUBTEXT ? process.env.REACT_APP_LANDING_PAGE_SUBTEXT : "Try things like activities and all...",

    /**
     * This is the landing page sub text for tips and tricks
     */
    landingPageSubTextForTripsTricks: process.env.REACT_APP_LANDING_PAGE_SUBTEXT_FOR_TIPS_AND_TRICKS ? process.env.REACT_APP_LANDING_PAGE_SUBTEXT_FOR_TIPS_AND_TRICKS : 'Tips and Tricks<br />- Use short terms and keywords to get the best results. Forexample, "Create Invoice" will return better results than "How tocreate Invoice?"',

    
}

export default config;