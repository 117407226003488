import React, { useState } from "react";
import config from "../../config/config";

export default function LandingSection({
  handleFaqSend,
  setInputtedQuestion,
  inputedQuestion,
}) {
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setKeyPressed(true);
      if (inputedQuestion.length > 0) handleFaqSend(setKeyPressed);
    }
  };

  const [keyPressed, setKeyPressed] = useState(false);

  const handleChange = (e) => {
    if (keyPressed) {
      setKeyPressed(false);
      return;
    }
    setInputtedQuestion(e.target.value);
  };

  return (
    <div className="faq-section-first flex-shrink-0">
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-center align-items-center flex-column faq-landing-search">
            <h1>{config.landingPageText}</h1>
            <div className="search-ladning w-100">
              <textarea
                className="form-control w-100"
                placeholder="Write your query...."
                onKeyDown={(e) => handleKeyPress(e)}
                onChange={(e) => handleChange(e)}
                value={inputedQuestion}
              ></textarea>
              <i className="far fa-search"></i>
            </div>
            <button className="btn" onClick={() => handleFaqSend()}>
              Start asking
            </button>
            <span
              className="landing-page-subText"
              dangerouslySetInnerHTML={{ __html: config.landingPageSubText }}
            >
              {/* <i className="fal fa-info-circle me-2"></i>
              <span></span>
              {config.landingPageSubText} */}
            </span>
            <span
              className="landing-page-subText-tips"
              dangerouslySetInnerHTML={{
                __html: config.landingPageSubTextForTripsTricks,
              }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
}
