import React, { useEffect, useState } from "react";
import config from "../../config/config";

export default function FaqFooter({
  handleFaqSend,
  setInputtedQuestion,
  inputedQuestion,
  loading,
}) {
  const [dots, setDots] = useState("");

  const [keyPressed, setKeyPressed] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (dots.length === 3) {
        setDots("");
      } else {
        setDots(dots + ".");
      }
    }, 500);
    return () => clearInterval(interval);
  }, [dots]);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setKeyPressed(true);
      if (inputedQuestion.length > 0) handleFaqSend(setKeyPressed);
    }
  };

  const handleChange = (e) => {
    if (keyPressed) {
      setKeyPressed(false);
      return;
    }
    setInputtedQuestion(e.target.value);
  };

  return (
    <div className="faq-footer mt-auto">
      <div className="bottom-form">
        <textarea
          className="form-control"
          placeholder="Write your query...."
          onKeyDown={(e) => handleKeyPress(e)}
          onChange={(e) => handleChange(e)}
          value={inputedQuestion}
          disabled={loading.current}
        ></textarea>
        {loading.current ? (
          <div className="send-icon-loading">{dots}</div>
        ) : (
          <button
            type="button"
            className="send-icon"
            disabled={inputedQuestion.length === 0 ? true : false}
            onClick={() => handleFaqSend()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M16.1 260.2c-22.6 12.9-20.5 47.3 3.6 57.3L160 376V479.3c0 18.1 14.6 32.7 32.7 32.7c9.7 0 18.9-4.3 25.1-11.8l62-74.3 123.9 51.6c18.9 7.9 40.8-4.5 43.9-24.7l64-416c1.9-12.1-3.4-24.3-13.5-31.2s-23.3-7.5-34-1.4l-448 256zm52.1 25.5L409.7 90.6 190.1 336l1.2 1L68.2 285.7zM403.3 425.4L236.7 355.9 450.8 116.6 403.3 425.4z" />
            </svg>
          </button>
        )}
        <div className="row mt-2">
          <div className="col-12 col-md-12 text-center text-md-start">
            <span className="d-block developed-by-block text-center">
              {config.websiteLogo === "invoiceOwl" &&
                "Developed by Space-O Technologies V-1.0 (embedding)"}
              {config.websiteLogo === "callHippo" &&
                "Developed by Space-O Technologies V-1.0 (call hippo)"}
              {config.websiteLogo === "allEvents" &&
                "Developed by Space-O Technologies V-1.0 (all events)"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
