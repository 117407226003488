import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import WrappedRoutes from "../../routes/WrappedRoutes";
import ToastNotification from "../../common/ToastNotification";
import config from "../../config/config";

const Main = () => {
  useEffect(() => {
    if (config.websiteLogo === "allEvents") {
      document.title = "All Events";
      document
        .querySelector("link[rel='icon']")
        .setAttribute("href", "/allEvents.ico");
    }
    if (config.websiteLogo === "callHippo") {
      document.title = "Call Hippo";
      document
        .querySelector("link[rel='icon']")
        .setAttribute("href", "/callHippo.ico");
    }
    if (config.websiteLogo === "invoiceOwl") {
      document.title = "Invoice Owl";
      document
        .querySelector("link[rel='icon']")
        .setAttribute("href", "/favicon copy.ico");
    }
  }, []);

  return (
    <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
      <WrappedRoutes />
      <ToastNotification />
    </BrowserRouter>
  );
};

export default Main;
